<template>
  <div class="confirmation-without-payment">
    <!-- confirmation_expires_at -->
    <div class="form-wrapper full">
      <p>{{ $t("BOOKINGS.SELECT_CONFIRMATION_EXPIRATION_DATE") }}</p>
      <base-input>
        <el-date-picker
          v-model="order.expiration_time"
          type="date"
          format="dd MMM yyyy"
          value-format="yyyy-MM-dd"
          :clearable="false"
          class="w-100"
        />
      </base-input>
    </div>

    <!-- comment -->
    <div class="form-wrapper full">
      <base-input :label="$t('BOOKINGS.INTERNAL_NOTE')">
        <el-input type="textarea" v-model="order.excerpt"></el-input>
      </base-input>
      <validation-error :errors="apiValidationErrors.excerpt" />
    </div>

    <hr />

    <!-- email -->
    <div class="form-wrapper full">
      <p>{{ $t("BOOKINGS.SEND_TO_CUSTOMER_EMAIL") }}</p>
      <base-input>
        <base-input type="email" v-model="order.email"></base-input>
      </base-input>
    </div>

    <div class="buttons-group">
      <el-button class="continue" type="primary" @click="handleSubmit">
        {{ $t("COMMON.CONTINUE") }}
      </el-button>
    </div>
  </div>
</template>
<script>
import { cloneDeep } from "lodash";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Header,
  Image,
  Row,
  Step,
  Steps,
  Select,
  Option,
} from "element-ui";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError";
import swal from "sweetalert2";

export default {
  name: "booking-confirmation-without-payment",

  components: {
    ValidationError,
    [Header.name]: Header,
    [Divider.name]: Divider,
    [Row.name]: Row,
    [Col.name]: Col,
    [Steps.name]: Steps,
    [Step.name]: Step,
    [Button.name]: Button,
    [Image.name]: Image,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: {
    orderData: {
      type: Object,
      description: "Sales order object",
    },
    formErrors: {
      type: Array | Object,
    },
  },

  data() {
    return {
      order: this.orderData,
      showModal: false,
      showBookingCheckModal: false,
      loading: false,
      // formErrors: null,
    };
  },

  created() {},

  mounted() {},

  computed: {},

  methods: {
    async handleSubmit() {
      const confirmation = await swal.fire({
        title: this.$t("BOOKINGS.CONFIRM_BOOKING"),
        html: this.$t("BOOKINGS.CONFIRM_THIS_BOOKING", {
          email: this.order.email,
        }),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      if (confirmation.isConfirmed) {
        const orderData = cloneDeep(this.order);

        this.$emit("onSubmitted", {
          id: this.order.id,
          email: this.order.email,
          excerpt: this.order.excerpt,
          expiration_time: this.order.expiration_time,
        });
      }
    },

    async sendInformation() {
      this.loading = true;

      try {
        await this.$store.dispatch("bookings/update", this.booking);
        this.$emit("bookingConfirmed", this.booking);
      } catch (error) {
        this.formErrors = error.response.data.errors;
      }
      this.loading = false;
    },

    onFormChanged() {
      this.$emit("onFormChanged", this.booking);
    },

    async toggleModalState() {
      this.showModal = !this.showModal;
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    orderData: {
      handler: function (orderData) {
        if (orderData) {
          this.order = {
            ...this.order,
            ...cloneDeep(orderData),
          };
        }
      },
      deep: true,
    },
  },
};
</script>
