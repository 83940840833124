<template>
  <div class="order-item__left-inner">
    <div class="order-item__left-inner-carousel">
      <el-carousel :autoplay="false" arrow="never" indicator-position="inside">
        <el-carousel-item v-for="img in booking.spot.gallery" :key="img">
          <img :src="img" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="order-item__left-inner-date">
      <div class="item">
        <h4>{{ $t("BOOKINGS.BOOKING_DATE") }}</h4>
        <p>
          {{ $formatDate(booking.start_at, "ll") }} -
          {{ $formatDate(booking.end_at, "ll") }}
        </p>
        <h3>{{ booking.spot.spotType.name }}</h3>
        <p>
          {{ booking.spot.code }}
        </p>
        <p class="status">
          <el-tag v-if="booking.is_blocked">
            <img src="/img/kw-lock.svg" alt="icon" />
          </el-tag>
          <el-tag v-if="booking.spot.locked">
            <img src="/img/kw-loading.svg" alt="icon" />
          </el-tag>
        </p>
      </div>

      <div class="item">
        <h4>{{ $t("BOOKINGS.BOOKING_DETAILS") }}</h4>
        <p>
          <span>
            {{ $t("BOOKINGS.CAPACITY") }}:
            {{ booking.adults_count + booking.children_count }}
          </span>
          <br />
        </p>
        <p
          v-if="
            booking.spot.category.booking_form_type === BOOKING_FORM_TYPE_LAND
          "
        >
          <span v-if="booking.beds_qty"
            >{{ $t("BOOKINGS.BEDS_QTY") }}: {{ booking.beds_qty }}<br
          /></span>
          <span v-if="booking.rooms_qty"
            >{{ $t("BOOKINGS.ROOMS_QTY") }}: {{ booking.rooms_qty }}<br
          /></span>
        </p>
        <p
          v-if="
            booking.spot.category.booking_form_type ===
            BOOKING_FORM_TYPE_PROPERTY
          "
        >
          <span v-if="booking.equipment"
            >{{ $t("BOOKINGS.SELECTED_EQUIPMENT") }}: {{ booking.equipment
            }}<br
          /></span>
          <span v-if="booking.equipment_length"
            >{{ $t("SPOTS.EQUIPMENT_LENGTH") }}: {{ booking.equipment_length
            }}<br
          /></span>
          <span v-if="booking.driver_extensions"
            >{{ $t("SPOTS.DRIVER_EXTENSION") }}: {{ booking.driver_extensions
            }}<br
          /></span>
          <span v-if="booking.passenger_extensions"
            >{{ $t("SPOTS.PASSENGER_EXTENSION") }}:
            {{ booking.passenger_extensions }}<br
          /></span>
          <span v-if="booking.electricity"
            >{{ $t("SPOTS.ELECTRICITY") }}: {{ booking.electricity }}<br
          /></span>
        </p>
        <p v-if="booking.special_request">
          {{ $t("BOOKINGS.SPECIAL_REQUEST") }}:
          <span v-html="booking.special_request"></span>
        </p>
      </div>
    </div>
    <div class="order-item__left-inner-summary">
      <el-button type="text" @click="() => editBooking(booking)">{{
        $t("COMMON.UPDATE")
      }}</el-button>
      <order-item-summary :item="orderItem"></order-item-summary>
    </div>
  </div>
</template>
<script>
import { Carousel, CarouselItem, Tag, Button } from "element-ui";
import OrderItemSummary from "./OrderItemSummary.vue";
import {
  BOOKING_FORM_TYPE_LAND,
  BOOKING_FORM_TYPE_PROPERTY,
} from "@/constants/spotCategories";

export default {
  name: "booking-card",
  components: {
    OrderItemSummary,
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem,
    [Tag.name]: Tag,
    [Button.name]: Button,
  },
  props: {
    orderItem: {
      type: Object,
      default: null,
      description: "orderItem object",
    },
  },
  data() {
    return {
      booking: this.orderItem.salesOrderable,
      BOOKING_FORM_TYPE_LAND: BOOKING_FORM_TYPE_LAND,
      BOOKING_FORM_TYPE_PROPERTY: BOOKING_FORM_TYPE_PROPERTY,
    };
  },
  methods: {
    editBooking(booking) {
      this.$emit("onEditBooking", booking);
    },
  },
};
</script>
